.memo-menu-handle {
    position: absolute;
    transition: opacity ease-in 0.2s;
    border-radius: 0.25rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M8 3V13' stroke='rgba(55, 53, 47, 0.3)' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M13 8L3 8' stroke='rgba(55, 53, 47, 0.3)' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    width: 1.2rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 99;
  }
  
  .memo-menu-handle:hover {
    background-color: #0d0d0d10;
    transition: background-color ease-out 0.2s;
  }
  