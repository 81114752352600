$out-of-range-color: #f2f5fc !default;
$out-of-range-bg-color: #f2f5fc !default;
$calendar-border: #f2f5fc !default;
$cell-border: #f2f5fc !default;
$border-color: #f2f5fc !default;

$segment-width: 0.14286% !default; // Each calendar segment is 1/7th.

$time-selection-color: white !default;
$time-selection-bg-color: rgba(#0039a7, 0.3) !default;
$date-selection-bg-color: rgba(0, 0, 0, 0.1) !default;

// $event-bg: white !default;
// $event-border: none !default;
$event-outline: none;
$event-color: #858994 !default;
// $event-border-radius: 8px !default;
// $event-padding: 2px 5px !default;
$event-padding: 0px !default;
$event-zindex: 4 !default;

$btn-color: #373a3c !default;
$btn-bg: #fff !default;
$btn-border: #ccc !default;

$current-time-color: red !default;

$rbc-css-prefix: rbc-i !default;
$today-highlight-bg: #eaf6ff !default;

@import 'react-big-calendar/lib/sass/styles.scss';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
